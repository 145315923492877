import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<any>;
  userData;
  authenticated: boolean = false;
  constructor(public afAuth: AngularFireAuth
    , private router: Router, public toastController: ToastController, public afs: AngularFirestore
    , public platform: Platform, private google: GooglePlus, public loadingController: LoadingController) {
    firebase.auth().getRedirectResult().then((result) => {
      if (result.user) {
        const data = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL
        };
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${data.uid}`);
        userRef.set(data, { merge: true })
        this.router.navigate(['home']);
        this.authenticated = true;
        localStorage.setItem('user', JSON.stringify(data));
        this.presentSucessToast('Haz iniciado sesión correctamente');
      }
    })
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  async presentLoading() {

  }

  getUserData() {
    return this.user$;
  }

  checkRedirect() {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {



    this.user$.subscribe(u => {
      if (!u) {
        this.router.navigate(["login"]);
        return false;
      }
    })

    return true;

  }

  async GoogleAuth() {

    if (this.platform.is('capacitor') || this.platform.is('cordova')) {
      this.mobileLogin();
    }
    else {
      return this.AuthLoginWeb(new firebase.auth.GoogleAuthProvider());
    }

  }

  mobileLogin() {
    let params;
    if (this.platform.is('android')) {
      params = {
        'webClientId': '985295138425-cgds07s4us0ra5cthboo7skc480nc1s4.apps.googleusercontent.com',
        'offline': true
      }
    }
    else {
      params = {}
    }
    this.google.login(params)
      .then((response) => {
        const { idToken, accessToken } = response
        this.onLoginSuccess(idToken, accessToken);
      }).catch((error) => {
        this.authenticated = false;
        console.log(error);
        this.presentErrorToast("Error al momento de inciar sesión")
      })
  }

  onLoginSuccess(accessToken, accessSecret) {
    const credential = accessSecret ? firebase.auth.GoogleAuthProvider
      .credential(accessToken, accessSecret) : firebase.auth.GoogleAuthProvider
        .credential(accessToken);
    this.afAuth.signInWithCredential(credential)
      .then((result) => {
        const data = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL
        }
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${data.uid}`);
        userRef.set(data, { merge: true })
        this.router.navigate(['home']);
        this.authenticated = true;
        this.presentSucessToast('Haz iniciado sesión correctamente');
      })

  }


  async AuthLoginWeb(provider) {
    try {
      this.afAuth.signInWithRedirect(provider);
    } catch (error) {
      this.authenticated = false;
      console.log(error);
      this.presentErrorToast("Error al momento de inciar sesión")
    }
  }

  SignOut() {
    return this.afAuth.signOut().then(() => {
      this.authenticated = false;
      this.router.navigate(['login']);
    })
  }

  async presentErrorToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: "danger"
    });
    toast.present();
  }

  async presentSucessToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: "success"
    });
    toast.present();
  }
}
