import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  constructor(public authService: AuthService) { }
  user;
  ngOnInit() {
    this.user = this.authService.getUserData();
  }

  logout() {
    this.authService.SignOut()
  }

}
