import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './shared/services/auth.service'

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthService]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    canActivate: [AuthService]
  },
  {
    path: 'create-event',
    loadChildren: () => import('./create-event/create-event.module').then(m => m.CreateEventPageModule),
    canActivate: [AuthService]
  },
  {
    path: 'product-modal',
    loadChildren: () => import('./product-modal/product-modal.module').then(m => m.ProductModalPageModule),
    canActivate: [AuthService]
  },
  {
    path: 'person-modal',
    loadChildren: () => import('./person-modal/person-modal.module').then(m => m.PersonModalPageModule),
    canActivate: [AuthService]
  },
  {
    path: 'view-event/:id',
    loadChildren: () => import('./view-event/view-event.module').then(m => m.ViewEventPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
